export const gameCard = {
    imgGameDeadZone: '/images/game_logo_deadzone.jpg',
    altDeadZone: 'Dead Zone: Zombies',
    imgGameBlackbeard: '/images/game_logo_blackbeard.jpg',
    altBlackbeard: 'Blackbeard: Trials of Skull Island',
    imgGameEliteForce: '/images/game_logo_eliteforce.jpg',
    altEliteForce: 'Elite Force',
    imgGameCoreDefense: '/images/game_logo_coredefense.jpg',
    altCoreDefense: 'Core Defense',
    imgGameParanormal: '/images/game_logo_paranormal.jpg',
    altParanormal: 'Paranormal: Enter The Manor',
    imgGameGunslinger: '/images/game_logo_gunslinger.jpg',
    altGunslinger: 'Gunslinger: Outlaw Shootout',
    imgGameHardpoint: '/images/game_logo_hardpoint.jpg',
    altHardpoint: 'Hardpoint',
    imgGameRhythmMaster: '/images/game_logo_rhythmmaster.jpg',
    altRhythmMaster: 'Rhythm Master',
  };