import React from 'react';
import './LazyAnimation.css';
import LazyLoad from 'react-lazy-load';

const LazyAnimation = () => {

  return (
    <LazyLoad debounce={false} throttle={0} height={15}>
    <div className='stretchAnimation'>
        &nbsp;
    </div>
    </LazyLoad>
  );
};

export default LazyAnimation;