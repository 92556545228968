export const activeCompetition = {
  // MONTHLY CONTEST:
  gameTitleMonth: "Dead Zone",
  imgMonth: "/images/active_comp_img_monthly_prizes_deadzone.jpg",
  monthCaption: "Monthly Tournament | October 1st - October 31st",
  subTitleMonth: "First Place Wins $2,000",

  //WEEKLY CONTEST:
  gameTitleWeek: "Blackbeard",
  imgWeek: "/images/active_comp_img_weekly_prizes_blackbeard.jpg",
  weekCaption: "Weekly Tournament | Monday thru Sunday",
  subTitleWeek: "First Place Wins $400",
  buttonLabel: "See Leaderboard",
  alt: "Omni Arena Competitions",
};

// LIST OF FILENAMES FOR CONTEST IMAGES:
// WEEKLY:
// Dead Zone: active_comp_img_weekly_prizes_deadzone.jpg
// Core Defense: active_comp_img_weekly_prizes_coredefense.jpg
// Gunslinger: active_comp_img_weekly_prizes_gunslinger.jpg
// Blackbeard: active_comp_img_weekly_prizes_blackbeard.jpg

// MONTHLY:
// Dead Zone: active_comp_img_monthly_prizes_deadzone.jpg
// Core Defense: active_comp_img_monthly_prizes_coredefense.jpg
// Gunslinger: active_comp_img_monthly_prizes_gunslinger.jpg
// Blackbeard: active_comp_img_monthly_prizes_blackbeard.jpg
