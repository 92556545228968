import React, { useState, useEffect } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import "./Navbar.css";
import { IconContext } from "react-icons/lib";
// import Announcebar from "./Announcebar";

function Navbar() {
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const showButton = () => {
    if (window.innerwidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  useEffect(() => {
    showButton();
  }, []);

  window.addEventListener("resize", showButton);

  return (
    <>
      <IconContext.Provider value={{ color: "#fff" }}>
        <div className="navbar">
          <div className="navbar-container">
            <a
              href="https://virtuix.com/omni-arena-for-players"
              className="navbar-logo"
              onClick={closeMobileMenu}
            >
              <img src="/images/omniarena_logo_color2_230px.svg" alt="" />
            </a>
            <div className="menu-icon" onClick={handleClick}>
              {click ? <FaTimes /> : <FaBars />}
            </div>
            <ul className={click ? "nav-menu active" : "nav-menu"}>
              <li className="nav-item">
                <a
                  href="https://virtuix.com/compete"
                  className="nav-links"
                  onClick={closeMobileMenu}
                >
                  Compete
                </a>
              </li>
              <li className="nav-item">
                <a
                  href="https://virtuix.com/omni-arena-leaderboards"
                  className="nav-links"
                  onClick={closeMobileMenu}
                >
                  Leaderboards
                </a>
              </li>
              <li className="nav-item">
                <a
                  href="https://virtuix.com/omni-arena-locations"
                  className="nav-links"
                  onClick={closeMobileMenu}
                >
                  Locations
                </a>
              </li>
            </ul>
          </div>
        </div>
      </IconContext.Provider>
    </>
  );
}

export default Navbar;
