import "./App.css";
import Navbar from "./components/Navbar";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Footer from "./components/pages/Footer/Footer";
import Share from "./components/pages/Share/Share";
import OmniArenaAccount from "./components/pages/Account/OmniArenaAccount";
import OmniArenaResetPassword from "./components/pages/ResetPassword/OmniArenaResetPassword";

function App() {
  return (
    <>
      <Router>
        <Navbar />
        <Switch>
          <Route path="/" exact component={Share} />
          <Route path="/omni-arena-account-confirmation" component={OmniArenaAccount} />
          <Route path="/omni-arena-reset-password" component={OmniArenaResetPassword} />
        </Switch>
        <Footer />
      </Router>
    </>
  );
}

export default App;
