import React, { useEffect, useState } from "react";
import "./SharePage.css";
import LazyAnimation from "./LazyAnimation";
import AllGames from "./AllGames";
import { gameCard } from './GamesData';
import CompetitionSectionShare from './CompetitionSectionShare';
import { activeCompetition } from './CompetitionSectionData';
import ShareOmniOneSection from "./ShareOmniOneSection";
import { shareObjOne } from "./ShareOmniOneSectionData";
import SharePlayer from "./SharePlayer";

export function SharePage() {
  const [username, setUsername] = useState();
  const [venue, setVenue] = useState();
  useEffect(()=>{
    fetch("https://api.playerhub.omniverse.global/social/" + window.location.search.substring(4) + "/info")
    .then(result => result.json())
    .then(result => {
      setUsername(result.nickname);
      setVenue(result.shopName);
    });
  },[])
    return (
      <div>
      <div className="share-hero-bg">
        <div className="share-container">
        <div className="share-hero">
          <h1 className="share-title">Good Game, {username}!</h1>
          <LazyAnimation></LazyAnimation>
          <h2 className="share-subtitle">
            Thank you for playing Omni Arena at {venue}.
          </h2>
        </div>
        <SharePlayer />
      </div>
      <CompetitionSectionShare {...activeCompetition} />
      <AllGames {...gameCard} />      
      <ShareOmniOneSection {...shareObjOne} />
      </div>
      </div>
    );
  }
  
  export default SharePage;