import React, { useState, useEffect } from "react";
import LazyAnimation from "../../LazyAnimation";
import AllGames from "../../AllGames";
import { gameCard } from "../../GamesData";
import CompetitionSectionShare from "../../CompetitionSectionShare";
import { activeCompetition } from "../../CompetitionSectionData";
import ShareOmniOneSection from "../../ShareOmniOneSection";
import { shareObjOne } from "../../ShareOmniOneSectionData";
import { AccountConfirmModal } from "../../AccountConfirm";

function OmniArenaResetPassword() {
    const [accountConfirm, setAccountConfirm] = useState(false);

    let url = window.location.href;

    useEffect(() => {
      setTimeout(() => {
        if (url.indexOf("?token") !== -1) {
          setAccountConfirm(true);
        }
      }, 0);
    }, [url]);

  return (
    <>
      <div className="share-hero-bg">
        <div className="share-container">
          <div className="share-hero">
            <h1 className="share-title">Thank you for playing Omni Arena!</h1>
            <LazyAnimation></LazyAnimation>
            <h2 className="share-subtitle">
              Play again and compete for cash prizes!
            </h2>
          </div>
        </div>
        <CompetitionSectionShare {...activeCompetition} />
        <AllGames {...gameCard} />
        <ShareOmniOneSection {...shareObjOne} />
        <AccountConfirmModal
          onClose={() => setAccountConfirm(false)}
          show={accountConfirm}
        />
      </div>
    </>
  );
}

export default OmniArenaResetPassword;
