import React from "react";
import { CSSTransition } from "react-transition-group";
import "./VideoModal.css";
import ReactPlayer from 'react-player/youtube';

const VideoModalDeadZone = props => {

    return (
        // <CSSTransition
        //     in={props.show}
        //     unmountOnExit
        //     timeout={{ enter: 0, exit: 300 }}
        // >
        //     <div className={`video_modal ${props.show ? 'show' : ''}`} onClick={props.onClose}>
        //         <div onClick={e => e.stopPropagation()}>
        //             <div className="player-wrapper">
        //                 <img className='video_close-modal' src="/images/x-close-green.svg" alt='' onClick={props.onClose} />
        //                 <ReactPlayer className="react-player" url='https://www.youtube.com/watch?v=ZTdXuX7ANr8'  controls="true" muted="true" playsinline="true" playing="true" width="55vw" />
        //             </div>                    
        //         </div>
                
        //     </div>
        // </CSSTransition>

        <CSSTransition
            in={props.show}
            unmountOnExit
            timeout={{ enter: 0, exit: 300 }}
        >
            <div className={`video_modal ${props.show ? 'show' : ''}`} onClick={props.onClose}>
                <div className="video_modal-content" onClick={e => e.stopPropagation()}>
                <img className='video_close-modal' src="/images/x-close-green.svg" alt='' onClick={props.onClose} />
                    <video id="trailer" playsInline autoPlay controls className="video_style_lightbox">
                        <source src="/video/oa_trailer_deadzone.mp4" type="video/mp4" />
                        Sorry, your browser doesn't support embedded videos.
                    </video>
                </div>
            </div>
        </CSSTransition>
    )
}

const VideoModalEliteForce = props => {

    return (
        <CSSTransition
            in={props.show}
            unmountOnExit
            timeout={{ enter: 0, exit: 300 }}
        >
            <div className={`video_modal ${props.show ? 'show' : ''}`} onClick={props.onClose}>
                <div className="video_modal-content" onClick={e => e.stopPropagation()}>
                <img className='video_close-modal' src="/images/x-close-green.svg" alt='' onClick={props.onClose} />
                    <video id="trailer" playsInline autoPlay controls className="video_style_lightbox">
                        <source src="/video/oa_trailer_eliteforce.mp4" type="video/mp4" />
                        Sorry, your browser doesn't support embedded videos.
                    </video>
                </div>
            </div>
        </CSSTransition>
    )
}

const VideoModalGunslinger = props => {

    return (
        <CSSTransition
            in={props.show}
            unmountOnExit
            timeout={{ enter: 0, exit: 300 }}
        >
            <div className={`video_modal ${props.show ? 'show' : ''}`} onClick={props.onClose}>
                <div className="video_modal-content" onClick={e => e.stopPropagation()}>
                <img className='video_close-modal' src="/images/x-close-green.svg" alt='' onClick={props.onClose} />
                    <video id="trailer" playsInline autoPlay controls className="video_style_lightbox">
                        <source src="/video/oa_trailer_gunslinger.mp4" type="video/mp4" />
                        Sorry, your browser doesn't support embedded videos.
                    </video>
                </div>
            </div>
        </CSSTransition>
    )
}

const VideoModalParanormal = props => {

    return (
        <CSSTransition
            in={props.show}
            unmountOnExit
            timeout={{ enter: 0, exit: 300 }}
        >
            <div className={`video_modal ${props.show ? 'show' : ''}`} onClick={props.onClose}>
                <div className="video_modal-content" onClick={e => e.stopPropagation()}>
                <img className='video_close-modal' src="/images/x-close-green.svg" alt='' onClick={props.onClose} />
                    <video id="trailer" playsInline autoPlay controls className="video_style_lightbox">
                        <source src="/video/oa_trailer_paranormal.mp4" type="video/mp4" />
                        Sorry, your browser doesn't support embedded videos.
                    </video>
                </div>
            </div>
        </CSSTransition>
    )
}

const VideoModalBlackbeard = props => {

    return (
        <CSSTransition
            in={props.show}
            unmountOnExit
            timeout={{ enter: 0, exit: 300 }}
        >
            <div className={`video_modal ${props.show ? 'show' : ''}`} onClick={props.onClose}>
                <div className="video_modal-content" onClick={e => e.stopPropagation()}>
                <img className='video_close-modal' src="/images/x-close-green.svg" alt='' onClick={props.onClose} />
                    <video id="trailer" playsInline autoPlay controls className="video_style_lightbox">
                        <source src="/video/oa_trailer_blackbeard.mp4" type="video/mp4" />
                        Sorry, your browser doesn't support embedded videos.
                    </video>
                </div>
            </div>
        </CSSTransition>
    )
}

const VideoModalCoreDefense = props => {

    return (
        <CSSTransition
            in={props.show}
            unmountOnExit
            timeout={{ enter: 0, exit: 300 }}
        >
            <div className={`video_modal ${props.show ? 'show' : ''}`} onClick={props.onClose}>
                <div className="video_modal-content" onClick={e => e.stopPropagation()}>
                <img className='video_close-modal' src="/images/x-close-green.svg" alt='' onClick={props.onClose} />
                    <video id="trailer" playsInline autoPlay controls className="video_style_lightbox">
                        <source src="/video/oa_trailer_coredefense.mp4" type="video/mp4" />
                        Sorry, your browser doesn't support embedded videos.
                    </video>
                </div>
            </div>
        </CSSTransition>
    )
}

const VideoModalHardpoint = props => {

    return (
        <CSSTransition
            in={props.show}
            unmountOnExit
            timeout={{ enter: 0, exit: 300 }}
        >
            <div className={`video_modal ${props.show ? 'show' : ''}`} onClick={props.onClose}>
                <div className="video_modal-content" onClick={e => e.stopPropagation()}>
                <img className='video_close-modal' src="/images/x-close-green.svg" alt='' onClick={props.onClose} />
                    <video id="trailer" playsInline autoPlay controls className="video_style_lightbox">
                        <source src="/video/oa_trailer_hardpoint.mp4" type="video/mp4" />
                        Sorry, your browser doesn't support embedded videos.
                    </video>
                </div>
            </div>
        </CSSTransition>
    )
}

const VideoModalRhythmMaster = props => {

    return (
        <CSSTransition
            in={props.show}
            unmountOnExit
            timeout={{ enter: 0, exit: 300 }}
        >
            <div className={`video_modal ${props.show ? 'show' : ''}`} onClick={props.onClose}>
                <div className="video_modal-content" onClick={e => e.stopPropagation()}>
                <img className='video_close-modal' src="/images/x-close-green.svg" alt='' onClick={props.onClose} />
                    <video id="trailer" playsInline autoPlay controls className="video_style_lightbox">
                        <source src="/video/oa_trailer_rhythmmaster.mp4" type="video/mp4" />
                        Sorry, your browser doesn't support embedded videos.
                    </video>
                </div>
            </div>
        </CSSTransition>
    )
}

export { VideoModalDeadZone, VideoModalEliteForce, VideoModalGunslinger, VideoModalParanormal, VideoModalBlackbeard, VideoModalCoreDefense, VideoModalHardpoint, VideoModalRhythmMaster }