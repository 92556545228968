import React from "react";
import { CSSTransition } from "react-transition-group";
import "./ImageModal.css";

const ImageModal1 = props => {

    return (
        <CSSTransition
            in={props.show}
            unmountOnExit
            timeout={{ enter: 0, exit: 300 }}
        >
            <div className={`image_modal ${props.show ? 'show' : ''}`} onClick={props.onClose}>
                <div className="image_modal-content" onClick={e => e.stopPropagation()}>
                <img className='image_close-modal' src="/images/x-close-green.svg" alt='' onClick={props.onClose} />
                <img className="image_modal-snapshot" src={"http://content.omniverse.global/media/"+window.location.search.substring(4)+"/snapshot1.png"} onError={(e)=>{ if (e.target.src !== "https://develop.arena.virtuix.com/images/arena_no_img.png"){
              e.target.src="https://develop.arena.virtuix.com/images/arena_no_img.png";}
            }
      } alt="Screenshot" />                
                </div>
            </div>
        </CSSTransition>
    )
}
const ImageModal2 = props => {

    return (
        <CSSTransition
            in={props.show}
            unmountOnExit
            timeout={{ enter: 0, exit: 300 }}
        >
            <div className={`image_modal ${props.show ? 'show' : ''}`} onClick={props.onClose}>
                <div className="image_modal-content" onClick={e => e.stopPropagation()}>
                <img className='image_close-modal' src="/images/x-close-green.svg" alt='' onClick={props.onClose} />
                <img className="image_modal-snapshot" src={"http://content.omniverse.global/media/"+window.location.search.substring(4)+"/snapshot2.png"} onError={(e)=>{ if (e.target.src !== "https://develop.arena.virtuix.com/images/arena_no_img.png"){
              e.target.src="https://develop.arena.virtuix.com/images/arena_no_img.png";}
            }
      } alt="Screenshot" />                
                </div>
            </div>
        </CSSTransition>
    )
}

const ImageModal3 = props => {

    return (
        <CSSTransition
            in={props.show}
            unmountOnExit
            timeout={{ enter: 0, exit: 300 }}
        >
            <div className={`image_modal ${props.show ? 'show' : ''}`} onClick={props.onClose}>
                <div className="image_modal-content" onClick={e => e.stopPropagation()}>
                <img className='image_close-modal' src="/images/x-close-green.svg" alt='' onClick={props.onClose} />
                <img className="image_modal-snapshot" src={"http://content.omniverse.global/media/"+window.location.search.substring(4)+"/snapshot3.png"} onError={(e)=>{ if (e.target.src !== "https://develop.arena.virtuix.com/images/arena_no_img.png"){
              e.target.src="https://develop.arena.virtuix.com/images/arena_no_img.png";}
            }
      } alt="Screenshot" />                
                </div>
            </div>
        </CSSTransition>
    )
}

const ImageModal4 = props => {

    return (
        <CSSTransition
            in={props.show}
            unmountOnExit
            timeout={{ enter: 0, exit: 300 }}
        >
            <div className={`image_modal ${props.show ? 'show' : ''}`} onClick={props.onClose}>
                <div className="image_modal-content" onClick={e => e.stopPropagation()}>
                <img className='image_close-modal' src="/images/x-close-green.svg" alt='' onClick={props.onClose} />
                <img className="image_modal-snapshot" src={"http://content.omniverse.global/media/"+window.location.search.substring(4)+"/snapshot4.png"} onError={(e)=>{ if (e.target.src !== "https://develop.arena.virtuix.com/images/arena_no_img.png"){
              e.target.src="https://develop.arena.virtuix.com/images/arena_no_img.png";}
            }
      } alt="Screenshot" />                
                </div>
            </div>
        </CSSTransition>
    )
}

export { ImageModal1, ImageModal2, ImageModal3, ImageModal4 }