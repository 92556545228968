import React from "react";
import { CSSTransition } from "react-transition-group";
import { Link } from 'react-router-dom';
import "./ShareModal.css";

const ShareTikTokModal = props => {
    
    const styleObjWidth22 = {
        width: "22px",
      }

    return (
        <CSSTransition
            in={props.show}
            unmountOnExit
            timeout={{ enter: 0, exit: 300 }}
        >
            <div className={`share-modal ${props.show ? 'show' : ''}`} onClick={props.onClose}>
                <div className="share-modal-content" onClick={e => e.stopPropagation()}>
                    <div className="share-modal-header">
                      <h4 className="share-modal-title">{props.title}</h4>
                      <Link className='close-share-modal'>                    
                      <img src="/images/x-close-green.svg" style={styleObjWidth22} alt='' onClick={props.onClose} /></Link>
                    </div>
                    <div className="background-green-share-modal">&nbsp;</div>
                    <div className="share-modal-body">
                        {props.children}
                    </div>
                    <div className="share-modal-footer">
                    <h2 className="share-modal-h2">How to share to TikTok</h2>
                    <p className="share-modal-copy-body">
                        <ol className="share-modal-indent">
                            <li className="share-modal-li">Press the <img className="share-share-icon-img-modal" src="/images/download_now_icon_dark.svg" alt="download icon" /> button to download the video.</li>
                            <li className="share-modal-li">Open the video from the download folder.</li>
                            <li className="share-modal-li">Press <img className="share-share-icon-img-modal" src="/images/android_share_now_icon_dark.svg" alt="share icon" /> at the bottom left to open the share menu.</li>
                            <li className="share-modal-li">Choose your Tiktok app and share your video.</li>
                        </ol>
                    </p>
                    </div>
                </div>
            </div>
        </CSSTransition>
    )
}

const ShareInstagramModal = props => {
    
    const styleObjWidth22 = {
        width: "22px",
      }

    return (
        <CSSTransition
            in={props.show}
            unmountOnExit
            timeout={{ enter: 0, exit: 300 }}
        >
            <div className={`share-modal ${props.show ? 'show' : ''}`} onClick={props.onClose}>
                <div className="share-modal-content" onClick={e => e.stopPropagation()}>
                    <div className="share-modal-header">
                      <h4 className="share-modal-title">{props.title}</h4>
                      <Link className='close-share-modal'>                    
                      <img src="/images/x-close-green.svg" style={styleObjWidth22} alt='' onClick={props.onClose} /></Link>
                    </div>
                    <div className="background-green-share-modal">&nbsp;</div>
                    <div className="share-modal-body">
                        {props.children}
                    </div>
                    <div className="share-modal-footer">
                    <h2 className="share-modal-h2">How to share to Instagram</h2>
                    <p className="share-modal-copy-body">
                        <ol className="share-modal-indent share-modal-ol">
                            <li className="share-modal-li">Press the <img className="share-share-icon-img-modal" src="/images/download_now_icon_dark.svg" alt="download icon" /> button to download the video.</li>
                            <li className="share-modal-li">Open the video from the download folder.</li>
                            <li className="share-modal-li">Press <img className="share-share-icon-img-modal" src="/images/android_share_now_icon_dark.svg" alt="share icon" /> at the bottom left to open the share menu.</li>
                            <li className="share-modal-li">Choose your Instagram app and share your video.</li>
                        </ol>
                    </p>
                    </div>
                </div>
            </div>
        </CSSTransition>
    )
}

const ShareDownloadModal = props => {
    
    const styleObjWidth22 = {
        width: "22px",
      }

    return (
        <CSSTransition
            in={props.show}
            unmountOnExit
            timeout={{ enter: 0, exit: 300 }}
        >
            <div className={`share-modal-dl ${props.show ? 'show' : ''}`} onClick={props.onClose}>
                <div className="share-modal-content" onClick={e => e.stopPropagation()}>
                    <div className="share-modal-header">
                      <h4 className="share-modal-title">{props.title}</h4>
                      <Link className='close-share-modal'>                    
                      <img src="/images/x-close-green.svg" style={styleObjWidth22} alt='' onClick={props.onClose} /></Link>
                    </div>
                    <div className="background-green-share-modal-dl">&nbsp;</div>
                    <div className="share-modal-body">
                        {props.children}
                    </div>
                    <div className="share-modal-footer">
                    <h2 className="share-modal-h2">Your download will begin soon.</h2>
                    </div>
                </div>
            </div>
        </CSSTransition>
    )
}

export { ShareTikTokModal, ShareInstagramModal, ShareDownloadModal }